import * as React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer>
    <div className="fixed bottom-0 z-50 sm:flex shadow bg-white w-full sm:mt-8 border-t pl-6 pr-6 pt-3 pb-0">
      <div className="sm:w-full flex flex-col md:flex-row justify-between">
        <div className="flex flex-col">
          <p className="text-gray-300">Copyright {new Date().getFullYear()}</p>
        </div>

        <div className="flex flex-col">
          <div className="flex md:pt-0 lg:justify-end">
            <Link to="/conditions" className="mr-2">
              AGB
            </Link>{" "}
            <Link to="/imprint" className="mr-2">
              Impressum
            </Link>{" "}
            <Link to="/privacy" className="">
              Datenschutz
            </Link>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
