import * as React from "react"
// @ts-ignore
import PropTypes from "prop-types"
import { graphql, useStaticQuery, Link } from "gatsby"
import GatsbyImage from "gatsby-image"

const Header = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativePath: { regex: "/sub/" }
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fixed(height: 60) {
                ...GatsbyImageSharpFixed
              }
              original {
                src
              }
            }
          }
        }
      }
      markdownRemark(fileAbsolutePath: { regex: "/index.md/" }) {
        html
        frontmatter {
          keyColor
          title
        }
      }
    }
  `)

  const logo = data.allFile.edges.find(n => n.node.name === "logo").node
    .childImageSharp
  const headerBgColor = data.markdownRemark.frontmatter.keyColor

  return (
    <header
      className="fixed shadow w-full z-50 bg-white"
      style={{ background: headerBgColor }}
    >
      <div className="mt-6 p-6 pt-0 pb-5">
        <div className="w-full flex flex-row justify-between items-end">
          <div className="flex items-center flex-shrink-0 text-gray-900 mr-6">
            <Link to="/" title="Startseite">
              <GatsbyImage
                fixed={logo.fixed}
                alt="logo"
                fadeIn={false}
                durationFadeIn={0}
                className="header-image"
              />
            </Link>
          </div>
          <div className="flex flex-col">
            <div className="flex lg:justify-end">
              <Link
                to="/login"
                className="inline-block text-sm px-4 py-2 leading-none border rounded text-gray-900 border-gray-900 hover:border-gray-500 hover:text-gray-500 hover:bg-white lg:mt-0"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
