import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet";
import Header from "./header"
import "./layout.scss"
import Footer from "./footer"
import GatsbyImage from 'gatsby-image';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
    allFile(
      filter: {relativePath: {regex: "/sub/"}, extension: {regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/"}}
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
  `);

  const bgPath = data.allFile.edges.find((n)=> n.node.name ==='bg').node.childImageSharp;


  return (
    <div className="flex flex-col">
      <GatsbyImage
          fluid={bgPath.fluid}
          alt="bg"
          className="fixed-img"
      />
      <Helmet
          bodyAttributes={{
            class: "leading-normal tracking-normal text-gray-900",
          }}
      />
      <Header
        siteTitle={data.site.siteMetadata?.title || "Title"}
      />
      <main className="">{children}</main>
      <Footer></Footer>
    </div>
  )
}

export default Layout
